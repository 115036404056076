import { NgModule } from '@angular/core';

//import { CardCountModule } from './../generic/card-count/card-count.module';
import { HomeComponent } from './home.component';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { HomeRoutingModule } from './home-routing.module';
import { NavMenuComponent } from './../nav-menu/nav-menu.component';
import { ModalComponent } from './../modal/modal.component';

//import { LectureService } from './../lecture/lecture.service';
import { SharedModule } from './../shared/shared.module';
import { EmployeeService } from '../employee/employee.service';
import { EmployeeModule } from '../employee/employee.module';

import { ContabilModule } from '../contabil/contabil.module';
//import { EmployeeListComponent } from '../employee/employee-list/employee-list.component';
//import { EmployeeDetailComponent} from '../employee/employee-detail/employee-detail.component';
//import { EmployeeEditComponent } from '../employee/employee-edit/employee-edit.component';

//import { SpeakerModule } from '../speaker/speaker.module';
//import { NoteService } from '../note/note.service';
//import { SpeakerService } from '../speaker/speaker.service';
//import { TrackService } from '../track/track.service';

@NgModule({
  imports: [
    SharedModule,
   
    //CardCountModule,
    //SpeakerModule,
    HomeRoutingModule,
    EmployeeModule,
    ContabilModule
  ],
  declarations: [
    HomeComponent,
    HomeDashboardComponent,
    NavMenuComponent,
    ModalComponent,
    //EmployeeListComponent,
    //EmployeeDetailComponent,
    //EmployeeEditComponent
  ],
  providers: [
    EmployeeService
    //LectureService,
    //NoteService,
    //SpeakerService,
    //TrackService
  ]
})
export class HomeModule { }
