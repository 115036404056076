import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PoStorageService } from '@po-ui/ng-storage';

import { GenericService } from './../generic/service/generic.service';
import { User } from '../model/user';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class LoginService extends GenericService<User> {

  path = 'auth';

  constructor(http: HttpClient,  private storage: PoStorageService, cookieService: CookieService) {
    super(http, cookieService);
  }

  isLoggedIn(): Promise<any> {
    return this.storage.get('isLoggedIn');
  }

}
