export class Employee {
  id: string;
  name: string;
  designation: string;
  company: string;
  cityname: string;
  address: string;
  gender: string;

  constructor() { }
}
