
<po-page-default p-title="{{pageTitle}}"
              [p-actions]="actionsPage"
              >

  <div class="po-mb-3">
    <po-container 
                  p-no-border="false"
                  p-no-padding="true"
                  p-no-shadow="false">

      <div class="po-row">
        <po-input class="po-lg-4"
                  name="name"
                  [(ngModel)]="listFilter"
                  p-clean
                  p-label="Nome"
                  p-required>
        </po-input>
      </div>
    </po-container>
    </div>
    <ng-template [ngIf]="filteredEmployees?.length" [ngIfElse]="speakersNotFound">
      <po-table p-container="light"
                [p-columns]="columns"
                [p-items]="filteredEmployees"
                [p-actions]="actions">
      </po-table>
    </ng-template>

    <ng-template #speakersNotFound>
      <po-widget class="po-offset-lg-4 po-lg-4">
        <p class="po-font-text-large text-lecture-not-found">
          There is no speaker available!
        </p>
      </po-widget>
    </ng-template>

</po-page-default>
<div *ngIf="errorMessage" class="alert alert-danger">
  Error: {{ errorMessage }}
</div>

