import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PoTableColumn } from '@po-ui/ng-components';
import { GenericService } from '../generic/service/generic.service';
import { CookieService } from 'ngx-cookie-service';
import { Cotacoes } from '../model/cotacoes';
import { DatePipe } from '@angular/common'

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient, private datePipe: DatePipe) { }

  getCotacoes(): Observable<Cotacoes> {
    let cot: Observable<Cotacoes>;
    let myDate = new Date();

    myDate.setDate(myDate.getDate() - 1);
    
    let data = this.datePipe.transform(myDate, 'M-d-yyyy');

    console.log(data);

    let url: string;
    url = "https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarDia(dataCotacao=@dataCotacao)?@dataCotacao='" + data + "'&$top=100&$format=json";

    console.log(url);

    //cot = this.http.get<Cotacoes[]>(url).pipe();
    //return cot;
    return this.http.get<Cotacoes>(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(err) {
    alert(err);
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }

    console.error(err);
    return throwError(errorMessage);
  }

}  
