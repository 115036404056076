<!--<app-nav-menu></app-nav-menu>-->
<div class="po-wrapper">

  <po-toolbar 
              [p-profile]="profile"
              [p-profile-actions]="profileActions"
              [p-notification-actions]="notificationActions"
              [p-notification-number]="getNotificationNumber()"
              [p-title]="title">
  </po-toolbar>


  <!--p-collapsed="true"-->
  <po-menu [p-menus]="menus"
           p-short-logo="/assets/img/logo_valongo_simbolo.jpeg"
           p-logo="/assets/img/valongo.svg">
  </po-menu>
  <!--<po-menu-panel p-logo="string"
                 p-menus="menus2">
  </po-menu-panel>-->
  <router-outlet></router-outlet>
  <po-page-default>
    <div class="po-row">
      <po-widget class="po-md-6 po-lg-3 po-mb-2" p-title="Dólar Venda">
        <div class="po-font-subtitle po-text-center">R$ {{DolarVenda}}</div>
        <div class="po-text-center sample-widget-text-subtitle">www.bcb.gov.br</div>
      </po-widget>
      </div>
</po-page-default>
</div>
