import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PoStorageService } from '@po-ui/ng-storage';

import { ObjectLength } from './../../model/objectLength';
import { PoResponse } from '../../model/po-response.interface';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class GenericService<T> {

  private readonly urlApi = 'https://apivalongo.azurewebsites.net';
  path: string;

  cookieService: CookieService;

  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    cookieService: CookieService) {
    this.cookieService = cookieService;

    this.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + cookieService.get('token')});
  }


  delete(id: string): Observable<{}> {

    return this.http.delete<{}>(`${this.urlApi}/${this.path}/${id}`).pipe(map(() => (id), error => (error)));
  }


  get(): Observable<T[]> {
 
    return this.http.get<T[]>(`${this.urlApi}/${this.path}`, { headers: this.headers });
  }

  getById(id: string): Observable<T> {
    return this.http.get<T>(`${this.urlApi}/${this.path}/${id}`, { headers: this.headers });
  }

  getCount(): Observable<number> {
    return this.http.get<ObjectLength>(`${this.urlApi}/${this.path}/count/`).pipe(map(result => (result.length)));
  }

  post(entity: any): Observable<T> {
    return this.http.post<T>(`${this.urlApi}/${this.path}`, entity, { headers: this.headers });
  }

  postWithPath(path: string, entity: any): Observable<T> {
    return this.http.post<T>(`${this.urlApi}/${this.path}/${path}`, entity, { headers: this.headers });
  }

  put(entity: any): Observable<T> {
    return this.http.put<T>(`${this.urlApi}/${this.path}/${entity.id}`, entity, { headers: this.headers });
  }
}
