import { Component, OnInit } from '@angular/core';
//import { Contabil } from '../employee';
import { ContabilService } from '../contabil.service';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { PoBreadcrumbItem, PoNotificationService, PoPageAction, PoPageFilter, PoTableAction } from '@po-ui/ng-components';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contabil-list',
  templateUrl: './contabil-list.component.html',
  styleUrls: ['./contabil-list.component.css']
})
export class ContabilListComponent implements OnInit {
  pageTitle = 'Contabil';
  errorMessage = '';

  constructor(private employeeService: ContabilService,
    private router: Router,
    private poNotification: PoNotificationService) { }


  ngOnInit(): void {
    //    this.getEmployeeData();
    //  this.columns = this.employeeService.getColumns();

  }


}  
