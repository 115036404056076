import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PoStorageService } from '@po-ui/ng-storage';
import { PoMenuItem, PoDialogService, PoNotificationService, PoToolbarAction, PoToolbarProfile } from '@po-ui/ng-components';
import { Cotacoes, Value } from '../model/cotacoes';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  title = 'MEIRELUZ RIBEIRO LANDIM';
  cotacoes: Cotacoes;
  DolarVenda: string;

  constructor(
    private router: Router,
    private storage: PoStorageService,
    private poDialog: PoDialogService,
    private poNotification: PoNotificationService,
    private homeService: HomeService) { }


  ngOnInit(): void {
    this.buscaCotacoes();
  }

  menus: Array<PoMenuItem> = [
    { label: 'Home', icon: 'po-icon po-icon-home', link: './home', shortLabel: ' Home' },
    { label: 'Contábil', icon: 'po-icon po-icon-calculator', link: './contabil', shortLabel: 'Contábil' },
    { label: 'Agronegócio', icon: 'po-icon po-icon-agro-business', link: './employee', shortLabel: 'Employee' },
    { label: 'Arquivos', icon: 'po-icon po-icon-pdf', link: './employee', shortLabel: 'Employee' },
    { label: 'Logout', icon: 'po-icon po-icon-exit', action: this.logout.bind(this), shortLabel: 'Logout' },
    
  ];


  buscaCotacoes() {

    this.homeService.getCotacoes().subscribe(poResponse => {
   
      this.cotacoes = poResponse;
      let val: Array<any> = [];

      val = this.cotacoes.value;

      console.log(this.cotacoes);

      this.DolarVenda = val[0].cotacaoVenda;
      this.DolarVenda = this.DolarVenda.toString().replace('.', ',');

    },
      error => {
        this.poNotification.error(<any>error)
      }
    );
  }

  notificationActions: Array<PoToolbarAction> = [
    {
      icon: 'po-icon-news',
      label: 'PO news, stay tuned!',
      type: 'danger',
      action: item => this.onClickNotification(item)
    },
    {
      icon: 'po-icon-message',
      label: 'New message',
      type: 'danger',
      action: item => this.openDialog(item)
    }
  ];

  profile: PoToolbarProfile = {
    avatar: 'https://via.placeholder.com/48x48?text=AVATAR',
    subtitle: 'dev@po-ui.com.br',
    title: 'Mr. Dev PO'
  };

  profileActions: Array<PoToolbarAction> = [
    { icon: 'po-icon-user', label: 'Perfil', action: item => this.showAction(item) },
    { icon: 'po-icon-company', label: 'Dados da Empresa', action: item => this.showAction(item) },
    { icon: 'po-icon-settings', label: 'Configurações', action: item => this.showAction(item) },
    { icon: 'po-icon-exit', label: 'Sair', type: 'danger', separator: true, action: item => this.logout() }
  ];

  //actions: Array<PoToolbarAction> = [
  //  { label: 'Start cash register', action: item => this.showAction(item) },
  //  { label: 'Finalize cash register', action: item => this.showAction(item) },
  //  { label: 'Cash register options', action: item => this.showAction(item) }
  //];



  getNotificationNumber() {
    return this.notificationActions.filter(not => not.type === 'danger').length;
  }

  onClickNotification(item: PoToolbarAction) {
    window.open('https://github.com/po-ui/po-angular/blob/master/CHANGELOG.md', '_blank');

    item.type = 'default';
  }

  openDialog(item: PoToolbarAction) {
    this.poDialog.alert({
      title: 'Welcome',
      message: `Hello Mr. Dev! Congratulations, you are a TOTVER!`,
      ok: undefined
    });

    item.type = 'default';
  }

  showAction(item: PoToolbarAction): void {
    this.poNotification.success(`Action clicked: ${item.label}`);
  }

  logout(): void {

    this.storage.remove('isLoggedIn').then(() => {
      this.router.navigate(['/login']);
    });
  }


}
