import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
//import { Employee } from './employee';
import { environment } from 'src/environments/environment';
import { PoTableColumn } from '@po-ui/ng-components';
import { GenericService } from '../generic/service/generic.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ContabilService  {

  private employeesUrl = environment.baseUrl + 'api/contabil';
  path = 'api/contabil';

  constructor(http: HttpClient, cookieService: CookieService) { }


  private handleError(err) {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }

  
  //       { property: 'id', label: 'ID' },
  getColumns(): Array<PoTableColumn> {
    return [
      { property: 'name', label: 'Nome' },
      { property: 'address', label: 'Endereço' },
      { property: 'gender', label: 'Gênero' },
      { property: 'company', label: 'Empresa' },
      { property: 'designation', label: 'Cargo' },
    ];
  }

}  
