import { NgModule } from '@angular/core';

import { ContabilRoutingModule } from './../contabil/contabil-routing.module';
import { SharedModule } from './../shared/shared.module';
import { ContabilListComponent } from './contabil-list/contabil-list.component';


@NgModule({
  imports: [
    SharedModule,
    ContabilRoutingModule
  ],
  declarations: [
    ContabilListComponent,
  ],
  exports: [
    ContabilListComponent,
  ],
  providers: [
    ContabilListComponent
  ]
})
export class ContabilModule { }
