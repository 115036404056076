import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { PoModule } from '@po-ui/ng-components';
import { PoStorageModule } from '@po-ui/ng-storage';

import { AuthGuardService } from '../auth/auth-guard.service';
import { GenericService } from './../generic/service/generic.service';

import { CookieService } from 'ngx-cookie-service';

//import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
//import { Token } from '@angular/compiler/src/ml_parser/lexer';

//export function tokenGetter() {
//  var token: string = localStorage.getItem('token');
//  console.log(token);
//  return token;
//}

//export function getAuthScheme(request) {
//  return "Bearer ";
//}

//export function jwtOptionsFactory() {
//  return {
//    tokenGetter,
//    authScheme: getAuthScheme,
//  };
//}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PoModule,
    PoStorageModule.forRoot({
      name: 'appconference',
      storeName: 'mystore',
      driverOrder: ['localstorage']
    }),
    //JwtModule.forRoot({
    //  jwtOptionsProvider: {
    //    provide: JWT_OPTIONS,
    //    useFactory: jwtOptionsFactory,
    //  },
    //}),
  ],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PoModule,
    PoStorageModule
  ],
  providers: [
    AuthGuardService,
    GenericService,
    CookieService
  ]
})
export class SharedModule { }
