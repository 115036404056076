import { NgModule } from '@angular/core';

import { EmployeeRoutingModule } from './../employee/employee-routing.module';
import { SharedModule } from './../shared/shared.module';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeeDetailComponent } from './employee-detail/employee-detail.component';
import { EmployeeEditComponent } from './employee-edit/employee-edit.component';


@NgModule({
  imports: [
    SharedModule,
    EmployeeRoutingModule
  ],
  declarations: [
    EmployeeListComponent,
    EmployeeDetailComponent,
    EmployeeEditComponent,
  ],
  providers: [
    EmployeeListComponent
  ]
})
export class EmployeeModule { }
