<po-page-edit p-title="Employee"
              [p-title]="title"
              [p-disable-submit]="formEmployee.invalid"
              (p-save)="save()"
              (p-cancel)="cancel()">

  <form #formEmployee="ngForm">

    <!--<form novalidate
    (ngSubmit)="saveEmployee()"
    [formGroup]="employeeForm">-->

    <div class="po-row">
      <po-input class="po-lg-8"
                name="name"
                [(ngModel)]="employee.name"
                p-clean
                p-label="Nome"
                p-required>
      </po-input>
      <po-select class="po-lg-4 po-md-6"
                 name="gender"
                 [(ngModel)]="employee.gender"
                 p-label="Gênero"
                 [p-options]="selectOptions"
                 p-required>
      </po-select>

    </div>
    <div class="po-row">
      <po-input class="po-lg-4"
                name="company"
                [(ngModel)]="employee.company"
                p-clean
                p-label="Company"
                p-required>
      </po-input>
      <po-input class="po-lg-3"
                name="designation"
                [(ngModel)]="employee.designation"
                p-clean
                p-label="Cargo"
                p-required>
      </po-input>
    </div>
    <div class="po-row">
      <po-input class="po-lg-4"
                name="city"
                [(ngModel)]="employee.cityname"
                p-clean
                p-label="Cidade"
                p-required>
      </po-input>
      <po-input class="po-lg-4"
                name="address"
                [(ngModel)]="employee.address"
                p-clean
                p-label="Endereço"
                p-required>
      </po-input>
    </div>

  </form>
</po-page-edit>
