import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Employee } from './employee';
import { environment } from 'src/environments/environment';
import { PoTableColumn } from '@po-ui/ng-components';
import { GenericService } from '../generic/service/generic.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends GenericService<Employee> {

  private employeesUrl = environment.baseUrl + 'api/employees';
  path = 'api/employees';

  constructor(http: HttpClient, cookieService: CookieService) { super(http, cookieService); }

  //getEmployees(): Observable<Employee[]> {
  //  console.log(this.employeesUrl);
  //  return this.http.get<Employee[]>(this.employeesUrl)
  //    .pipe(
  //      catchError(this.handleError)
  //    );
  //}

  //getEmployee(id: string): Observable<Employee> {
  //  if (id === '') {
  //    return of(this.initializeEmployee());
  //  }
  //  const url = `${this.employeesUrl}/${id}`;
  //  return this.http.get<Employee>(url)
  //    .pipe(
  //      catchError(this.handleError)
  //    );
  //}

  //createEmployee(employee: Employee): Observable<Employee> {
  //  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //  return this.http.post<Employee>(this.employeesUrl, employee, { headers: headers })
  //    .pipe(
  //      catchError(this.handleError)
  //    );
  //}

  //deleteEmployee(id: string): Observable<{}> {
  //  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //  const url = `${this.employeesUrl}/${id}`;
  //  return this.http.delete<Employee>(url, { headers: headers })
  //    .pipe(
  //      catchError(this.handleError)
  //    );
  //}

  //updateEmployee(employee: Employee): Observable<Employee> {
  //  debugger
  //  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //  const url = `${this.employeesUrl}/${employee.id}`;
  //  return this.http.put<Employee>(url, employee, { headers: headers })
  //    .pipe(
  //      map(() => employee),
  //      catchError(this.handleError)
  //    );
  //}

  private handleError(err) {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }

  private initializeEmployee(): Employee {
    return {
      id: null,
      name: null,
      address: null,
      gender: null,
      company: null,
      designation: null,
      cityname: null
    };
  }

  //       { property: 'id', label: 'ID' },
  getColumns(): Array<PoTableColumn> {
    return [
      { property: 'name', label: 'Nome' },
      { property: 'address', label: 'Endereço' },
      { property: 'gender', label: 'Gênero' },
      { property: 'company', label: 'Empresa' },
      { property: 'designation', label: 'Cargo' },
    ];
  }

  //getColumns(): Array<PoTableColumn> {
  //  return [
  //    { property: 'ID', label: 'ID' },
  //    { property: 'Name', label: 'Nome' },
  //    { property: 'Company', label: 'Empresa' },
  //    { property: 'CityName', label: 'Fisica/Juridica' },
  //    { property: 'CpfCnpj', label: 'CPF/CNPJ' },
  //  ];
  //}
}  
