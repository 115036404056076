import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployeeDetailComponent } from '../employee/employee-detail/employee-detail.component';
import { EmployeeEditComponent } from './../employee/employee-edit/employee-edit.component';
import { EmployeeListComponent } from '../employee/employee-list/employee-list.component';
import { ContabilListComponent } from '../contabil/contabil-list/contabil-list.component';

import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { HomeComponent } from './home.component';
import { AuthGuardService } from './../auth/auth-guard.service';

const homeRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'employee',
        children: [
          { path: '', component: EmployeeListComponent, canActivate: [AuthGuardService] },
          { path: 'create', component: EmployeeEditComponent, canActivate: [AuthGuardService] },
          { path: 'detail/:id', component: EmployeeDetailComponent },
          { path: 'edit/:id', component: EmployeeEditComponent }
        ]


      },
      {
        path: 'contabil',
        children: [
          { path: '', component: ContabilListComponent, canActivate: [AuthGuardService] },
        ]


      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(homeRoutes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
