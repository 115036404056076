import { Component, OnInit } from '@angular/core';
import { Employee } from '../employee';
import { EmployeeService } from '../employee.service';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { PoBreadcrumbItem, PoNotificationService, PoPageAction, PoPageFilter, PoTableAction } from '@po-ui/ng-components';
import { Router } from '@angular/router';

import { PoResponse } from '../../model/po-response.interface';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {
  pageTitle = 'Employees';
  filteredEmployees: Employee[] = [];
  employees: Array<Employee> = [];
  errorMessage = '';

  actions: Array<PoTableAction> = [
    { action: this.updateEmployee.bind(this), icon: 'po-icon-edit', label: 'Editar' }
    //{ action: this.deleteCustomer.bind(this), icon: 'po-icon-delete', label: 'Excluir Cliente' }
  ];

  actionsPage: Array<PoPageAction> = [
    /*  { url: '../home/employee/create', icon: 'po-icon-plus', label: 'Novo' }*/
    { url: '../home/employee/create', icon: 'po-icon-plus', label: 'Novo', type: 'default' }
  ];

  columns: Array<any>;

  _listFilter = '';
  get listFilter(): string {
    return this._listFilter;
  }
  set listFilter(value: string) {
    this._listFilter = value;
    this.filteredEmployees = this.listFilter ? this.performFilter(this.listFilter) : this.employees;
  }

  constructor(private employeeService: EmployeeService,
    private router: Router,
    private poNotification: PoNotificationService) { }

  performFilter(filterBy: string): Employee[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.employees.filter((employee: Employee) =>
      employee.name.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }

  ngOnInit(): void {
    this.getEmployeeData();
    this.columns = this.employeeService.getColumns();

    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(environment.baseUrl + 'notify')
      .build();

    connection.start().then(function () {
      console.log('SignalR Connected!');
    }).catch(function (err) {
      return console.error(err.toString());
    });

    connection.on("BroadcastMessage", () => {
      this.getEmployeeData();
    });
  }

  getEmployeeData() {

    this.employeeService.get().subscribe(poResponse => {
      // this.employees.push(...poResponse);
      //. console.log(poResponse);
      this.employees = poResponse;
      this.filteredEmployees = this.employees;

    },
      error => {
        console.log(error);
        this.poNotification.error(<any>error)
      }
    );
  }

  updateEmployee(row: any) {
    const customerId = row.id;
    //console.log(customerId);
    //alert(customerId);
    this.router.navigate([`../home/employee/edit/${customerId}`]);
  }

  //deleteEmployee(id: string, name: string): void {
  //  if (id === '') {
  //    this.onSaveComplete();
  //  } else {
  //    if (confirm(`Are you sure want to delete this Employee: ${name}?`)) {
  //      this.employeeService.deleteEmployee(id)
  //        .subscribe(
  //          () => this.onSaveComplete(),
  //          (error: any) => this.errorMessage = <any>error
  //        );
  //    }
  //  }
  //}

  //onSaveComplete(): void {
  //  this.employeeService.getEmployees().subscribe(
  //    employees => {
  //      this.employees = employees;
  //      this.filteredEmployees = this.employees;
  //    },
  //    error => this.errorMessage = <any>error
  //  );
  //}


  filter: PoPageFilter = {
    action: this.filterEmployeeByName.bind(this),
    placeholder: 'Name'
  };

  filterEmployeeByName(speakerName: string) {
    if (speakerName) {
      this.filteredEmployees = this.employees.filter(speaker => speaker.name.toLowerCase().includes(speakerName.toLocaleLowerCase()));
    } else {
      this.getEmployeeData();
    }
  }
}  
