import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { PoNotificationService } from '@po-ui/ng-components';
import { PoPageLogin } from '@po-ui/ng-templates';
import { PoStorageService } from '@po-ui/ng-storage';

import { LoginService } from './login.service';
import { User } from '../model/user';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  hideRememberUser: boolean = true;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private storage: PoStorageService,
    private poNotification: PoNotificationService,
    private cookieService: CookieService) { }

  loginSubmit(formData: PoPageLogin) {
    const user = Object.assign({ login: formData.login, senha: formData.password });

    //console.log(user);

    this.loginService.postWithPath('login', user).subscribe(a => {
      this.storage.set('user', a);
      this.storage.set('token', a.token);
      this.cookieService.set('token', a.token);
      //console.log('cookie: ' + this.cookieService.get('token'));

      //this.storage.get('token');

      this.storage.set('isLoggedIn', 'true').then(() => {

        this.router.navigate(['/']);
      });
    }, () => {
      this.poNotification.error('Invalid username or password. Please try again.');
    });

  }

}
