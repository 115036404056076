
<po-page-default p-title="{{pageTitle}}">
  <style>
    .num {
      text-align: right;
    }

    .bordRight {
      border-right: 1px solid;
    }

    .txtCenter {
      text-align: center;
    }

    .txtRight {
      text-align: right;
    }

    .negrito {
      font-weight: bold;
    }

    .negritoLeve {
      font-weight: 600;
    }
  </style>

  <po-container p-no-border="false"
                p-no-padding="true"
                p-no-shadow="false">

    <table class="table table-striped table-hover">
      <thead class="thead-dark">
        <tr class="negrito">
          <th></th>
          <th></th>
          <!--**-->
          <th class="col-md-">A.H.</th>
          <th class="txtCenter">2021</th>
          <th class="col">A.V.</th>
          <!--**-->
          <th class="col-md-1">A.H.</th>
          <th class="txtCenter">2020</th>
          <th class="col">A.V.</th>
          <!--**-->
          <th class="col-md-1">A.H.</th>
          <th class="txtCenter">2019</th>
          <th class="col">A.V.</th>
          <!--**-->

        </tr>
      </thead>
      <tr class="negrito">
        <td>ATIVO</td>
        <td class="col-md-2 bordRight"></td>
        <td class="col-md-1 txtRight">%</td>
        <td>2.162.774,55</td>
        <td class="col bordRight txtRight">%</td>
        <!--**-->
        <td class="col-md-1 txtRight">%</td>
        <td>2.162.774,55</td>
        <td class="col bordRight txtRight">%</td>
        <!--**-->
        <td class="col-md-1 txtRight">%</td>
        <td>2.162.774,55</td>
        <td class="col txtRight txtRight">%</td>
        <!--**-->
      </tr>
      <tr>
      </tr>
      <tbody>
        <tr class="negritoLeve">
          <td></td>
          <td class="bordRight col-md-2">Ativo Circulante</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">1.441.849,70</td>
          <td class="num bordRight col-md-2">66,67%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">1.441.849,70</td>
          <td class="num bordRight col-md-2">66,67%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td>1.441.849,70</td>
          <td class="num bordRight">66,67%</td>
          <!--**-->
        </tr>
        <tr>
          <td></td>
          <td class="bordRight col-md-2">Disponível</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">720.949,85</td>
          <td class="num bordRight col-md-2">33,67%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr>
          <td></td>
          <td class="bordRight col-md-2">Adiantamentos para aquisições de áreas rurais</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr>
          <td></td>
          <td class="bordRight col-md-2">Tributos a recuperar</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr>
          <td></td>
          <td class="bordRight col-md-2">Apropriação de exercícos futuros</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr class="negritoLeve mb-5">
          <td></td>
          <td class="bordRight col-md-2">Ativo não circulante</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr>
          <td></td>
          <td class="bordRight col-md-2">Apropriação de exercícos futuros</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr>
          <td></td>
          <td class="bordRight col-md-2">Adiatamentos para aquisições futuras </td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr>
          <td></td>
          <td class="bordRight col-md-2">Participações Societárias</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr>
          <td></td>
          <td class="bordRight col-md-2">Imobilizado</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr>
          <td></td>
          <td class="bordRight col-md-2">Construções em andamento</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr>
          <td></td>
          <td class="bordRight col-md-2">Quotas de consórcio</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr>
          <td></td>
          <td class="bordRight col-md-2"> Máquinas e implementos agrícolas </td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr class="negrito">
          <td>PASSIVO</td>
          <td class="col-md-2 bordRight"></td>
          <td class="col-md-1 txtRight">%</td>
          <td>-2.162.774,55</td>
          <td class="col bordRight txtRight">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td>2.162.774,55</td>
          <td class="col bordRight txtRight">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td>2.162.774,55</td>
          <td class="col txtRight txtRight">%</td>
          <!--**-->
        </tr>
        <tr class="negritoLeve">
          <td></td>
          <td class="bordRight col-md-2">Passivo Circulante</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
        <tr class="negritoLeve">
          <td></td>
          <td class="bordRight col-md-2">Fornecedores</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight col-md-2">%</td>
          <!--**-->
          <td class="col-md-1 txtRight">%</td>
          <td class="txtRight">0,00</td>
          <td class="num bordRight">%</td>
          <!--**-->
        </tr>
      </tbody>
    </table>
    <!--<div class="po-row">
      <po-input class="po-lg-4"
                name="name"
                [(ngModel)]="listFilter"
                p-clean
                p-label="Nome"
                p-required>
      </po-input>
    </div>-->
  </po-container>

</po-page-default>
