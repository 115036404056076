import { Component, Input, OnInit, OnDestroy } from '@angular/core';
/*import { FormControlName, FormGroup, FormBuilder, Validators } from '@angular/forms';*/
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Employee } from './../../model/employee';
import { EmployeeService } from '../employee.service';
import { PoNotificationService, PoSelectOption } from '@po-ui/ng-components';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.css']
})
export class EmployeeEditComponent implements OnInit {

  isUpdate: boolean = false;
  speakerOptions: Array<PoSelectOption> = [];
  employees: Array<Employee> = [];
  title: string = 'Create lecture';
  trackOptions: Array<PoSelectOption> = [];
  //teste: Array<> = [];
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };

  /** Objeto do tipo Lecture referente a palestra. */
  @Input('employee') employee: Employee = new Employee();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employeeService: EmployeeService,
    private poNotification: PoNotificationService,) { }

  //this.validationMessages = {
  //  name: {
  //    required: 'Employee name is required.',
  //    minlength: 'Employee name must be at least three characters.',
  //    maxlength: 'Employee name cannot exceed 50 characters.'
  //  },
  //  cityname: {
  //    required: 'Employee city name is required.',
  //  }
  //};


  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.getEmployeeById(params['id'].toString());
      }
    });

    // usar para cidade
    //this.employeeService.get().subscribe(employeeResponse => {

    //  employeeResponse.items.forEach(track => {
    //    if (track.deleted === false) {
    //      this.trackOptions = [...this.trackOptions, { value: track.id, label: track.name }];
    //      this.tracks = [...this.tracks, track];
    //    }
    //  });

    //if (this.isUpdate) {
    //  this.lecture.trackId = this.lecture.track.id;
    //}


    // GUARDAR PARA CIDADE
    //  this.employeeService.get().subscribe(employeeResponse => {

    //    employeeResponse.items.forEach(speaker => {
    //    if (speaker.deleted === false) {
    //      this.speakerOptions = [...this.speakerOptions, { value: speaker.id, label: speaker.name }];
    //      this.Employees = [...this.Employees, speaker];
    //    }
    //  });

    //  if (this.isUpdate) {
    //    this.lecture.speakerId = this.lecture.speaker.id;
    //  }
    //});
  }

  cancel() {
    this.navigateToPath('home/employee');
  }

  create() {
    this.employeeService.post(this.employee).subscribe(lecture => {
      this.poNotification.success(`Employee ${this.employee.name} criado com sucesso!`);
      this.navigateToPath('home/employee');
    }, error => {
      this.poNotification.error(error.status + ' ' + error.statusText);
    });
  }

  edit() {
    this.employeeService.put(this.employee).subscribe(employee => {
      this.poNotification.success(`Employee ${this.employee.name} atualizado com sucesso!`);
      this.navigateToPath('home/employee');
    }, error => {
      this.poNotification.error(error.status + ' ' + error.statusText);
    });
  }

  getEmployeeById(id: string) {
    this.employeeService.getById(id).subscribe(employee => {
      this.employee = employee;

      //this.title = `Edit employee ${this.employee.name}`;
      this.title = `Edit employee`;
      this.isUpdate = true;
    }, error => {
      this.poNotification.error(error.status + ' ' + error.statusText);
    });
  }

  public readonly selectOptions: Array<PoSelectOption> = [
    { value: '', label: 'Selecione' },
    { value: 'Female', label: 'Feminimo' },
    { value: 'Male', label: 'Masculino' }
  ];
  //getSpeakerImage(EmployeeId: string) {
  //  return this.speakers.find(speaker => speaker.id === speakerId).photo;
  //}

  //getTrackColor(trackId: string) {
  //  return this.tracks.find(track => track.id === trackId).color;
  //}

  save() {
    //this.transformTimeMaskLecture();
    if (this.isUpdate) {
      this.edit();
    } else {
      this.create();
    }
  }

  private navigateToPath(path: string) {
    this.router.navigate([path]);
  }

}  
